//This is it new

import { initializeApp } from "firebase/app";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const firebaseConfig = {
  apiKey: process.env.React_app_firebase_key,
  authDomain: "newkoka-86fa6.firebaseapp.com",
  projectId: "newkoka-86fa6",
  storageBucket: "newkoka-86fa6.appspot.com",
  messagingSenderId: "693568299190",
  appId: "1:693568299190:web:675956d45cbd2e07b84617",
  measurementId: "G-MH0E3EGVE8"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();
const db = firebase.firestore();
export { db, auth };
