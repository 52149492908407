import logo from "./img/logonobgf.png";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import background from "./img/pizzabg.jpg";
import background2 from "./img/bg2.jpg";
import background3 from "./img/bg3.jpg";
import lasagna from "./img/lasagna.png";
import pizza from "./img/pizza.png";
import coke from "./img/coke.png";
import fish from "./img/fishchip.png";
import salad from "./img/salad.png";
import sides from "./img/sides.png";
import slide2 from "./img/slide2.png";
import React, { useState, useEffect } from "react";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import "./App.css";
import Home from "./home";
import Pizza from "./pizza";
import Toppings from "./toppings";
import Salad from "./salad";
import Saladtoppings from "./saladtopping";
import Pizzawings from "./pizzawings";
import Cart from "./cart";
import Store from "./store";

import Test from "./test";
import Kokaside from "./kokaside";
import PrintReceipt from "./print";
import Printer from "./print";
import ThermalReceipt from "./print";
import Wingstopping from "./wingstopping";
import Pwoption from "./pizzawingoption";
import Dealtoppings from "./dealtoppings";
import Dealwings from "./dealtopwings";
import Sides from "./sides";
import Panzitoppings from "./panzitops";
import TimePickerExample from "./test";
import Fries from "./friess";
import Lasagna from "./Lasagna";
import Seafood from "./seafood";
import Shrimptops from "./shrimptops";
import Special from "./special";
import Garlicfingers from "./garlicfinger";
import Box from "./box";
import Boxtopping from "./boxtopping";
import Family from "./family";
import Pop from "./pop";
import Partydeals from "./partydeals";
import Partytopps from "./partytopps";
import Partywings from "./partywings";
import Dip from "./dip";
function App() {
  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/pizza" element={<Pizza />} />
          <Route path="/toppings" element={<Toppings />} />
          <Route path="/wingstopping" element={<Wingstopping />} />
          <Route path="/pwo" element={<Pwoption />} />
          <Route path="/dealtops" element={<Dealtoppings />} />
          <Route path="/dealtopwings" element={<Dealwings />} />
          <Route path="/sides" element={<Sides />} />
          <Route path="/panzitops" element={<Panzitoppings />} />
          <Route path="/fried" element={<Fries />} />
          <Route path="/lasagna" element={<Lasagna />} />
          <Route path="/seafood" element={<Seafood />} />
          <Route path="/shrimptops" element={<Shrimptops />} />
          <Route path="/special" element={<Special />} />
          <Route path="/boxes" element={<Box />} />
          <Route path="/boxtopping" element={<Boxtopping />} />
          <Route path="/family" element={<Family />} />
          <Route path="/pop" element={<Pop />} />
          <Route path="/party" element={<Partydeals />} />
          <Route path="/partytops" element={<Partytopps />} />
          <Route path="/partywings" element={<Partywings />} />
          <Route path="/garlicfingers" element={<Garlicfingers />} />
          <Route path="/salad" element={<Salad />} />
          <Route path="/saladtopping" element={<Saladtoppings />} />
          <Route path="/pizzawings" element={<Pizzawings />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/an" element={<Store />} />
          <Route path="/test" element={<TimePickerExample />} />
          <Route path="/print" element={<ThermalReceipt />} />
          <Route path="/dip" element={<Dip />} /> */}
          <Route path="/kokapicca2018" element={<Kokaside />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
