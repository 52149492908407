import logo from "./img/logonobgf.png";
import plu from "./img/plus.png";
import min from "./img/minus.png";
import React, { useCallback, useState, useEffect, useRef } from "react";
import "react-slideshow-image/dist/styles.css";
import "./App.css";
import "./kokaside.css";
import chime from "./ringtone/chime.mp3";
import "./topping.css";
import { MdOutlineClose } from "react-icons/md";
import { FcApproval, FcProcess } from "react-icons/fc";
import { Button, message, Space } from "antd";
import {
  connectFirestoreEmulator,
  serverTimestamp,
  terminate,
} from "firebase/firestore";
import BurgerMenu from "./burgermenu.js";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { useLocation } from "react-router-dom";
import emailjs from '@emailjs/browser';
import {
  createSearchParams,
  useSearchParams,
  useNavigate,
  NavLink,
} from "react-router-dom";
import ThermalReceipt from "./print";
import ReactModal from "react-modal";
import { db, auth } from "./firebase";
import { fi } from "date-fns/locale";
ReactModal.setAppElement("#root");
function Kokaside() {
  const form = useRef();
  const [hour, setHour] = useState(Number("00"));
  const [min, setMin] = useState(Number("00"));
  const [tempmin, setTempmin] = useState();
  const [temphour, setTemphour] = useState();
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [isOpen, setIsOpen] = useState();
  const [openTime, setOpenTime] = useState();
  const [clearTime, setClearTime] = useState();
  const [closeTime, setcloseTime] = useState();
  const [shiftStart, setShiftStart] = useState(null);
  const [inputValue, setInputValue] = useState("");

  const [page, setPage] = useState(true);
  const [email, setEmail] = useState(false);
  const viewemails = () => {
    setEmail(true);
  };
  

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentDate = new Date();
      const currentDay = currentDate.getDay(); // 0 is Sunday, 1 is Monday, etc.
      if (currentDay === 1 && currentDate.getHours() >= 15) {
        openstore();
      }
      if (
        currentDay >= 2 &&
        currentDay <= 5 &&
        (currentDate.getHours() >= 15 || currentDate.getHours() < 1)
      ) {
        openstore();
      }
      if (
        currentDay === 6 &&
        (currentDate.getHours() >= 15 || currentDate.getHours() < 2)
      ) {
        openstore();
      }
      if (
        currentDay === 0 &&
        ((currentDate.getHours() >= 15 && currentDate.getHours() < 23) ||
          currentDate.getHours() < 2)
      ) {
        openstore();
      }

      if (currentDay === 1 && currentDate.getHours() < 15) {
        closestore();
      }
      if (
        currentDay >= 2 &&
        currentDay <= 5 &&
        currentDate.getHours() >= 1 &&
        currentDate.getHours() < 15
      ) {
        closestore();
      }

      if (
        currentDay === 6 &&
        currentDate.getHours() >= 2 &&
        currentDate.getHours() < 15
      ) {
        closestore();
      }

      if (
        currentDay === 0 &&
        ((currentDate.getHours() >= 2 && currentDate.getHours() < 15) ||
          currentDate.getHours() >= 23)
      ) {
        closestore();
      }
    }, 60 * 1000); // Check every minute

    return () => clearInterval(intervalId);
  }, []);

  // const complete = () => {
  //   const currentTime = new Date().getTime();
  //   const cuurn = new Date();
  //   // console.log(currentTime);
  //   db.collection("Neworder")
  //     .get()
  //     .then((stuff) => {
  //       stuff.forEach((each) => {
  //         console.log(each.data().approved.toMillis());
  //         console.log(
  //           currentTime -
  //             (parseInt(each.data().Timehour) * 60 +
  //               parseInt(each.data().Timemin)) *
  //               60 *
  //               1000
  //         );
  //         if (
  //           each.data().approved.toMillis() <
  //           currentTime -
  //             (parseInt(each.data().Timehour) * 60 +
  //               parseInt(each.data().Timemin)) *
  //               60 *
  //               1000
  //         ) {
  //           db.collection("Completedorder")
  //             .add(each.data())
  //             .then(() => {
  //               // Delete the document from the "Neworder" collection
  //               console.log("exsecuted in time");
  //               db.collection("Neworder").doc(each.id).delete();
  //             })
  //             .catch((error) => {
  //               console.error("Error moving document:", error);
  //             });
  //         }
  //       });
  //     });
  // };

  const success = () => {
    message.open({
      type: "success",
      content: "Email sent successfully",
    });
  };

  const error = () => {
    message.open({
      type: "error",
      content: "There was an error sending Email",
    });
  };
  const sende = (hour, min, item) => {
    const config = {



      SecureToken: process.env.React_app_smtp,
      To: item.Email,
      // To: item.data,
      From: "kokaspizza@gmail.com",
      Subject: "Order confirmation",
      Body: `<img src='http://kokas-pizza.com/wp-content/uploads/2018/11/KokasPizzaLogo1000x723.png' alt="Paris" style="width:100px"><h4 style="color:Green";font-family:"MyFont">Hi ${item.Name}, your order has been confirmed</h4><p>Your ${item.Method} will be ready in ${hour} hr ${min} min</p>`,
    };
    if (window.Email) {
      window.Email.send(config)
        .then(success)
        .catch((e) => {
          error();
        });
    }
  };

  const complete = async () => {
    const currentTime = new Date().getTime();

    const querySnapshot = await db.collection("Neworder").get();

    const batch = db.batch();

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      if (data.approved) {
        const orderTime =
          (parseInt(data.Timehour) * 60 + parseInt(data.Timemin)) * 60 * 1000;
        const approvedTime = data.approved.toMillis();

        if (approvedTime < currentTime - orderTime) {
          const completedOrderRef = db.collection("Completedorder").doc(doc.id);

          batch.set(completedOrderRef, data);
          batch.delete(doc.ref);
        }
      }
    });

    await batch.commit();
  };

  useEffect(() => {
    // Call the function every second using setInterval
    const intervalId = setInterval(complete, 1000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",

      width: "50vw",
      maxWidth: "800px",
      transform: "translate(-50%, -50%)",
      backgroundColor: "white",
    },
  };
  const customStyles2 = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",

      width: "70vw",
      maxWidth: "800px",
      transform: "translate(-50%, -50%)",
      backgroundColor: "white",
    },
  };
  const [menuOpen, setMenuOpen] = useState(false);

  const [modalState, setModalState] = useState({
    isOpen: false,
    selectedItem: null,
  });
  const [modalOpen, setModalOpen] = useState(true);
  const [userInteracted, setUserInteracted] = useState(false);
  const [itemCount, setItemCount] = useState(0);
  const [password, setPassword] = useState();
  const [width, setWidth] = useState(window.innerWidth);
  const [order, setOrder] = useState([]);
  const [corder, setCorder] = useState([]);
  const [emailA, setEmailA] = useState([]);
  const [modal, setModal] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const [temp, setTemp] = useState();
  useEffect(() => {
    db.collection("open")
      .doc("PWDJGjQWhU1Rtke9aWAE")
      .onSnapshot((sub) => {
        setIsOpen(sub.data().isOpen);
        setOpenTime(sub.data().opentime);
        setClearTime(sub.data().clearTime);
        settimee(sub.data().opentime);
        console.log(sub.data().opentime);
      });

   
    db.collection("Neworder")
      .orderBy("Time", "desc")
      .onSnapshot((items) => {
        setOrder([]);
        items.forEach((sub) => {
          setOrder((subb) => [...subb, sub]);
        });
      });
    setTemp(
      order.map((item, index) => {
        if (index === 0) {
          return item.data().Time;
        }
      })
    );

    const unsubscribe = db.collection("Neworder").onSnapshot((snapshot) => {
      setItemCount(snapshot.size);
    });

    // Listen for real-time updates to 'Neworder' collection
    const newOrderUnsubscribe = db
      .collection("Neworder")
      .onSnapshot((newOrderSnapshot) => {
        const newOrderEmails = newOrderSnapshot.docs.map(
          (doc) => doc.data().Email
        );

        // Merge the two arrays and remove duplicates
        const mergedEmails = [...newOrderEmails, ...emailA].filter(
          (email, index, self) => self.indexOf(email) === index
        );

        // Update state with merged emails
        setEmailA(mergedEmails);
      });

    // Listen for real-time updates to 'Completedorder' collection
    

    return () => {
      // Unsubscribe from real-time updates when the component unmounts
      unsubscribe();
      newOrderUnsubscribe();
    };
  }, []);

  const handleUserInteraction = () => {
    setUserInteracted(true);
  };

  useEffect(() => {
    document.addEventListener("click", handleUserInteraction);
    return () => document.removeEventListener("click", handleUserInteraction);
  }, []);
  useEffect(() => {
    const temp1 = order.map((item, index) => {
      if (index === 0) {
        return item.data().Time;
      }
    });
    if (temp < temp1) {
      const audio = new Audio(chime);
      audio.play();
      setTemp(temp1);
    }
  }, [order]);
  const openModal = (selectedItem) => {
    console.log(selectedItem.data());
    setModalState({
      isOpen: true,
      selectedItem: selectedItem,
    });
    console.log("this opens modal", selectedItem.data().Name);
  };

  const closeModal = () => {
    setModalState({
      isOpen: false,
      selectedItem: null,
    });
    console.log("this closes modal");
  };
  let openDate;
  const openstore = () => {
    db.collection("open")
      .doc("PWDJGjQWhU1Rtke9aWAE")
      .get()
      .then((sub) => {
        console.log(sub.data().isOpen);
        if (!sub.data().isOpen) {
          db.collection("open")
            .doc("PWDJGjQWhU1Rtke9aWAE")
            .update({
              isOpen: true,
              opentime: serverTimestamp(),
            })
            .catch((e) => alert("There was an error closing store: " + e))
            .then(setIsOpen(true));
        } else {
          console.log("Store is already open");
        }
      });
  };

  const summery = () => {
    let count = 0;
    let price = 0;
    let dp = 0;
    let pp = 0;
    let delivery = 0;
    let pickup = 0;

    openDate = new Date(openTime.toDate());
    openDate.setHours(15, 0, 0, 0);
    console.log(openDate);
    order.map((mapp) => {
      if (mapp.data().Time.toDate() >= openDate) {
        count = count + 1;
        price = price + parseFloat(mapp.data().Price);
        if (mapp.data().Method == "Delivery") {
          delivery = delivery + 1;
          dp = dp + parseFloat(mapp.data().Price);
        } else {
          pickup = pickup + 1;
          pp = pp + parseFloat(mapp.data().Price);
        }
      }
    });

    corder.map((mapp) => {
      if (mapp.data().Time.toDate() > openDate) {
        count = count + 1;
        price = price + parseFloat(mapp.data().Price);
        if (mapp.data().Method == "Delivery") {
          delivery = delivery + 1;
          dp = dp + parseFloat(mapp.data().Price);
        } else {
          pickup = pickup + 1;
          pp = pp + parseFloat(mapp.data().Price);
        }
      }
    });

    alert(
      `Total order = ${count} \nTotal price = $${price.toFixed(
        2
      )} \nTotal deliveries  = ${delivery} \nTotal pickup = ${pickup} \nTotal delivery price = $${dp.toFixed(
        2
      )} \nTotal pickup price = $${pp.toFixed(2)}`
    );
  };

  const closestore = () => {
    db.collection("open")
      .doc("PWDJGjQWhU1Rtke9aWAE")
      .get()
      .then((sub) => {
        console.log(sub.data().isOpen);
        if (sub.data().isOpen) {
          db.collection("open")
            .doc("PWDJGjQWhU1Rtke9aWAE")
            .update({
              isOpen: false,
              closetime: serverTimestamp(),
            })
            .catch((e) => alert("There was an error closing store: " + e))
            .then(setIsOpen(false));
        } else {
          console.log("Store is already closed");
        }
      });
  };

  const [tempp, setTempp] = useState();
  const [timee, setTimee] = useState();
  const settimee = (time) => {
    setTimee(new Date(time.toDate()));
  };
  const getapproved = (item, collection) => {
    console.log(collection);
    db.collection(collection)
      .doc(item.id)
      .get()
      .then((snap) => {
        if (snap.data()) {
          setTempp(snap.data().approved);
          console.log(item.id);
        } else {
          console.log("this is running");
        }

        openModal(item);
      });
  };
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm("service_h8ns29k", "template_tlmscmo", form.current, "PS-3HQmZAiUE38W5Y")
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          sendEmail2(e);
      });
  };

  const sendEmail2 = (e) => {
    e.preventDefault();

    emailjs.sendForm("service_ylo2bjn", "template_68q52el", form.current, "G4pZOnKEec19iVTiq")
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };
  const Modal = ({ isOpen, onClose, selectedItem }) => {
    console.log(tempp);
    console.log(selectedItem);
    const [temphour, setTemphout] = useState(Number("00"));
    const [tempmin, setTempmin] = useState(
      selectedItem.data().Method == "Pick Up" ? Number("15") : Number("45")
    );
    const handletempChange = useCallback((e) => {
      setTemphout(e.target.value);
    }, []);
    const handletminChange = useCallback((e) => {
      setTempmin(e.target.value);
    }, []);
    return (
      <ReactModal isOpen={isOpen} onRequestClose={onClose}>
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
          }}
        >
          <MdOutlineClose
            style={{
              alignSelf: "flex-end",
              fontSize: width < "468" ? 25 : 40,
              top: 0,
              color: "#ff0000",
              justifySelf: "flex-end",
            }}
            onClick={onClose}
          />
        </div>
        <ThermalReceipt item={selectedItem.data()} />

        {!tempp && (
          <div
            style={{
              display: "flex",
              // flexDirection: "column",
              // justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                marginRight: 10,
                fontSize: width < "468" ? "6vw" : "2vw",
                fontFamily: "MyFont",
              }}
            >
              <select
                value={temphour || hour || ""}
                onChange={handletempChange}
                style={{
                  fontSize: width < "468" ? "6vw" : "2vw",
                  fontFamily: "MyFont",
                }}
              >
                <option key={Number("00")} value={Number("00")}>
                  00
                </option>
                <option key={Number("01")} value={Number("01")}>
                  01
                </option>
                <option key={Number("02")} value={Number("02")}>
                  02
                </option>
              </select>
              hr:
              <select
                value={tempmin || min || ""}
                onChange={handletminChange}
                style={{
                  fontSize: width < "468" ? "6vw" : "2vw",
                  fontFamily: "MyFont",
                }}
              >
                <option key={Number("00")} value={Number("00")}>
                  00
                </option>
                <option key={Number("15")} value={Number("15")}>
                  15
                </option>
                <option key={Number("30")} value={Number("30")}>
                  30
                </option>
                <option key={Number("45")} value={Number("45")}>
                  45
                </option>{" "}
              </select>
              Min
            </div>
            <form ref={form} onSubmit={(e)=>{e.preventDefault();sendEmail(e);console.log("thiss");
                if (!(temphour == Number("00") && tempmin == Number("00"))) {
                  db.collection("Neworder")
                    .doc(selectedItem.id)
                    .update({
                      approved: serverTimestamp(),
                      Timehour: temphour,
                      Timemin: tempmin,
                    })
                    
                  onClose();
                  console.log("Time is not fine");
                  console.log(temphour + " " + tempmin);
                } else {
                  console.log("Time is fine");
                  console.log(temphour + " " + tempmin);
                }}}>
      
      {/* <input style={{width:0,height:0, visibility:"hidden"}} type="text" name="from_name" value={selectedItem.data().Name} disabled />
      
      <input type="email" name="send_to"   value="anmolriar8070@gmail.com" disabled/>
      
      <input type="text" name="message" style={{width:0,height:0, visibility:"hidden"}} value={`Your order has been confirmed and will be ready for ${selectedItem.data().Method} in ${temphour} hr ${tempmin} min.`} disabled/>
       */}
      <input style={{width:0,height:0, visibility:"hidden"}} type="text" name="from_name" value={selectedItem.data().Name} />
      
      <input type="email" name="send_to" style={{width:0,height:0, visibility:"hidden"}}  value={selectedItem.data().Email} />
      
      <input type="text" name="message" style={{width:0,height:0, visibility:"hidden"}} value={`Your order has been confirmed and will be ready for ${selectedItem.data().Method} in ${temphour} hr ${tempmin} min.`}/>
      <input type="submit" value="Accept" 
              style={{
                bottom: "10px",
                fontFamily: "MyFont",
                color: "white",
                width: width < "468" ? "10vh" : "13vh",
                fontSize: width < "468" ? "6vw" : "2vw",
                height: "auto",
                padding: 5,
                // alignSelf: "center",
                borderRadius: 25,
                background: "#ff0000",
                // marginLeft: 5,
              }}
              onClick={() => {
                
              }}
            />
    </form>
            
          </div>
        )}
      </ReactModal>
    );
  };
  const fetchcompletedorder=()=>{
    db.collection("Completedorder")
    .orderBy("approved", "desc")
    .onSnapshot((items) => {
      setCorder([]);
      items.forEach((sub) => {
        setCorder((subb) => [...subb, sub]);
      });
    });
    const completedOrderUnsubscribe = db
      .collection("Completedorder")
      .onSnapshot((completedOrderSnapshot) => {
        const completedOrderEmails = completedOrderSnapshot.docs.map(
          (doc) => doc.data().Email
        );

        // Merge the two arrays and remove duplicates
        const mergedEmails = [...completedOrderEmails, ...emailA].filter(
          (email, index, self) => self.indexOf(email) === index
        );

        // Update state with merged emails
        setEmailA(mergedEmails);
      });
      return ()=> {
        completedOrderUnsubscribe()
      }
  }
  return (
    <div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <div>
          <NavLink to={"/"} style={{ alignSelf: "flex-start" }}>
            <img
              src={logo}
              style={{
                width: width > "450" ? "30vw" : "40vw",
                alignSelf: "flex-start",
                margin: 20,
              }}
            />
          </NavLink>
        </div>
        <div style={{ margin: 20 }}>
          <button
            onClick={toggleMenu}
            style={{
              background: "white",
              border: 0,
              color: "black",
              fontFamily: "MyFont",
              fontSize: width < "468" ? "8vw" : "4vw",
            }}
          >
            ☰
          </button>
          <div
            className={`menu ${menuOpen ? "menu-open" : ""}`}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div
              style={{ alignSelf: "flex-end", marginTop: 20, marginRight: 20 }}
            >
              <button
                onClick={toggleMenu}
                style={{
                  background: "white",
                  border: 0,
                  fontFamily: "MyFont",
                  color: "#ff0000",
                  fontSize: width < "468" ? "8vw" : "4vw",
                }}
              >
                x
              </button>
            </div>
            <p
              style={{
                fontSize: width < "468" ? "6vw" : "2vw",
                fontFamily: "MyFont",
                marginLeft: 10,
              }}
              onClick={() => summery()}
            >
              Summery
            </p>
            <p
              style={{
                fontSize: width < "468" ? "6vw" : "2vw",
                fontFamily: "MyFont",
                marginLeft: 10,
              }}
              onClick={() => {
                setEmail(true);
              }}
            >
              Emails
            </p>
            {page ? (
              <p
                style={{
                  fontSize: width < "468" ? "6vw" : "2vw",
                  fontFamily: "MyFont",
                  marginLeft: 10,
                }}
                onClick={() => {
                  setPage(!page);
                  setEmail(false);
                }}
              >
                Completed Orders
              </p>
            ) : (
              <p
                style={{
                  fontSize: width < "468" ? "6vw" : "2vw",
                  fontFamily: "MyFont",
                }}
                onClick={() => {
                  setPage(!page);
                  setEmail(false);
                }}
              >
                New Orders
              </p>
            )}
          </div>
        </div>
      </div>
      <div
        style={{
          marginLeft: 20,
          marginRight: 20,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            maxWidth: "800px",
            width: "90vw",
          }}
        >
          <ReactModal
            isOpen={modalOpen}
            onRequestClose={() => setModalOpen(false)}
            contentLabel="Example Modal"
            backdrop="static"
            style={customStyles}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                // justifyContent: "center",
                // alignContent: "center",
              }}
            >
              <MdOutlineClose
                style={{
                  alignSelf: "flex-end",
                  fontSize: width < "468" ? 20 : 30,
                  top: 0,
                  color: "#ff0000",
                }}
                onClick={() => setModalOpen(false)}
              ></MdOutlineClose>
              <label
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                  fontFamily: "MyFont",
                  fontSize: width < "468" ? "6vw" : "3vw",
                }}
              >
                Welcome Back
                {/* <input
                  type="password"
                  onChange={setPassword}
                  style={{
                    marginTop: 10,
                    border: "0px",
                    borderBottom: "1px solid black",
                  }}
                /> */}
                {/* <p
                  style={{
                    color: "red",
                    fontFamily: "helvetica",
                    fontSize: 15,
                  }}
                >
                  Incorrect Password
                </p> */}
              </label>

              {/* <button
                onClick={() => {
                  setModalOpen(false);
                }}
                style={{
                  bottom: "10px",
                  fontFamily: "MyFont",
                  color: "white",
                  width: "20vh",
                  fontSize: width < "468" ? "6vw" : "2vw",
                  height: width < "450" ? "6.5vh" : "8vh",
                  alignSelf: "center",
                  borderRadius: 25,
                  background: "#ff0000",
                  margin: 10,
                }}
              >
                Submit
              </button> */}
            </div>
          </ReactModal>
          {!page && !email && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <lable
                style={{
                  fontFamily: "MyFont",

                  color: "#ff0000",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p style={{ fontSize: width < "468" ? "6vw" : "2vw" }}>
                  Search:
                </p>
                <input
                  type="text"
                  placeholder="Phone number"
                  value={inputValue}
                  onChange={handleInputChange}
                  style={{
                    fontSize: width < "468" ? "6vw" : "2vw",
                    fontFamily: "MyFont",
                    margin: 10,
                    border: 0,
                    borderBottom: "1px solid black",
                  }}
                />
                {inputValue && (
                  <MdOutlineClose
                    style={{ fontSize: width < "468" ? "6vw" : "2vw" }}
                    onClick={() => setInputValue("")}
                  />
                )}
              </lable>
            </div>
          )}
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            {isOpen ? (
              <p
                style={{
                  fontFamily: "MyFont",
                  fontSize: width > "468" && "2vw",
                  color: "#008000",
                }}
              >
                The store is open.
              </p>
            ) : (
              <p
                style={{
                  fontFamily: "MyFont",
                  fontSize: width > "468" && "2vw",
                  color: "#ff0000",
                }}
              >
                The store is closed.
              </p>
            )}
          </div>
          {/* <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
            }}
          >
            <button
              style={{
                bottom: "10px",
                fontFamily: "MyFont",
                color: "white",
                width: "20vh",
                fontSize: width < "468" ? "6vw" : "2vw",
                height: width < "450" ? "6.5vh" : "8vh",
                alignSelf: "center",
                borderRadius: 25,
                background: "#ff0000",
                margin: 10,
              }}
              onClick={() => {
                openstore();
              }}
            >
              Open Shift
            </button>
            <button
              style={{
                bottom: "10px",
                fontFamily: "MyFont",
                color: "white",
                width: "20vh",
                fontSize: width < "468" ? "6vw" : "2vw",
                height: width < "450" ? "6.5vh" : "8vh",
                alignSelf: "center",
                borderRadius: 25,
                background: "#ff0000",
                margin: 10,
              }}
              onClick={() => {
                closestore();
              }}
            >
              End Shift
            </button>
          </div> */}

          {!email ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              {page ? (
                <div>
                  {order.map((item, index) => {
                    // console.log(timee);
                    timee.setHours(15, 0, 0, 0);

                    if (item.data().Time.toDate() > timee) {
                      console.log(item.data().Time);
                      return (
                        <div
                          style={{
                            boxShadow: "2px 2px 4px #f0f0f0",
                            borderRadius: 5,
                            margin: 10,
                            fontFamily: "MyFont",
                            fontSize: width < "468" ? "6vw" : "2vw",
                          }}
                        >
                          <div
                            onClick={() => {
                              getapproved(item, "Neworder");
                            }}
                          >
                            {item.data().Method == "Delivery" ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <p>{item.data().Address}</p>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  {item.data().Timewant && (
                                    <p style={{ margin: 10 }}>
                                      {item.data().Timewant}
                                    </p>
                                  )}
                                  {item.data().approved && (
                                    <FcProcess style={{ margin: 10 }} />
                                  )}
                                </div>
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <div style={{ display: "flex" }}>
                                  <p style={{ color: "#ff0000" }}>
                                    {item.data().Method}
                                  </p>
                                  <p>, {item.data().Name}</p>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  {item.data().Timewant && (
                                    <p style={{ margin: 10 }}>
                                      {item.data().Timewant}
                                    </p>
                                  )}
                                  {item.data().approved && (
                                    <FcProcess style={{ margin: 10 }} />
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                          {modalState.isOpen && (
                            <Modal
                              isOpen={modalState.isOpen}
                              onClose={closeModal}
                              selectedItem={modalState.selectedItem}
                            />
                          )}
                        </div>
                      );
                    }
                  })}

                  {corder.map((item, index) => {
                    // console.log(timee);
                    timee.setHours(15, 0, 0, 0);
                    if (item.data().Time.toDate() > timee)
                      return (
                        <div
                          style={{
                            boxShadow: "2px 2px 4px #f0f0f0",
                            borderRadius: 5,
                            margin: 10,
                            fontFamily: "MyFont",
                            fontSize: width < "468" ? "6vw" : "2vw",
                          }}
                        >
                          <div
                            onClick={() => {
                              getapproved(item, "Completedorder");
                            }}
                          >
                            {item.data().Method == "Delivery" ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <p>{item.data().Address}</p>

                                <FcApproval style={{ margin: 10 }} />
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <div style={{ display: "flex" }}>
                                  <p style={{ color: "#ff0000" }}>
                                    {item.data().Method}
                                  </p>
                                  <p>, {item.data().Name}</p>
                                </div>
                                <FcApproval style={{ margin: 10 }} />
                              </div>
                            )}
                          </div>
                          {modalState.isOpen && (
                            <Modal
                              isOpen={modalState.isOpen}
                              onClose={closeModal}
                              selectedItem={modalState.selectedItem}
                              collection={"Completedorder"}
                            />
                          )}
                        </div>
                      );
                  })}
                </div>
              ) : (
                corder.map((item, index) => {
                  if (inputValue === "") {
                    return (
                      <div
                        style={{
                          boxShadow: "2px 2px 4px #f0f0f0",
                          borderRadius: 5,
                          margin: 10,
                          fontFamily: "MyFont",
                          fontSize: width < "468" ? "6vw" : "2vw",
                        }}
                      >
                        {/* <p>Time: {item.Time}</p> */}
                        {/* <p>Phone: {item.Phone}</p>
                  <p>Name: {item.Name}</p>
                  <p>Pay: {item.Pay}</p>
                  {toggle && (
                    <>
                      <p>
                        Order:{" "}
                        {JSON.parse(item.Order).map((items, index) => {
                          const toppingsList = items
                            .slice(2)
                            .map((topping, toppingIndex) => {
                              return <li key={toppingIndex}>{topping}</li>;
                            });
                          return (
                            <div key={index}>
                              <h2>
                                {items[0]} - {items[1]}
                              </h2>
                              <ul>{toppingsList}</ul>
                            </div>
                          );
                        })}
                      </p>
                      <p>Address: {item.Address}</p>
                    </>
                  )}
                  <p>Method: {item.Method}</p>
                  <p>Price: {item.Price}</p>
                  <p>----------------------</p> */}
                        <div
                          onClick={() => {
                            getapproved(item, "Completedorder");
                          }}
                        >
                          {item.data().Method == "Delivery" ? (
                            <div>
                              <p>{item.data().Address}</p>
                            </div>
                          ) : (
                            <div style={{ display: "flex" }}>
                              <p style={{ color: "#ff0000" }}>
                                {item.data().Method}
                              </p>
                              <p>, {item.data().Name}</p>
                            </div>
                          )}
                        </div>
                        {modalState.isOpen && (
                          <Modal
                            isOpen={modalState.isOpen}
                            onClose={closeModal}
                            selectedItem={modalState.selectedItem}
                            collection={"Completedorder"}
                          />
                        )}
                      </div>
                    );
                  } else {
                    if (inputValue === item.data().Phone) {
                      return (
                        <div
                          style={{
                            boxShadow: "2px 2px 4px #f0f0f0",
                            borderRadius: 5,
                            margin: 10,
                            fontFamily: "MyFont",
                            fontSize: width < "468" ? "6vw" : "2vw",
                          }}
                        >
                          {/* <p>Time: {item.Time}</p> */}
                          {/* <p>Phone: {item.Phone}</p>
                      <p>Name: {item.Name}</p>
                      <p>Pay: {item.Pay}</p>
                      {toggle && (
                        <>
                          <p>
                            Order:{" "}
                            {JSON.parse(item.Order).map((items, index) => {
                              const toppingsList = items
                                .slice(2)
                                .map((topping, toppingIndex) => {
                                  return <li key={toppingIndex}>{topping}</li>;
                                });
                              return (
                                <div key={index}>
                                  <h2>
                                    {items[0]} - {items[1]}
                                  </h2>
                                  <ul>{toppingsList}</ul>
                                </div>
                              );
                            })}
                          </p>
                          <p>Address: {item.Address}</p>
                        </>
                      )}
                      <p>Method: {item.Method}</p>
                      <p>Price: {item.Price}</p>
                      <p>----------------------</p> */}
                          <div
                            onClick={() => {
                              getapproved(item, "Completedorder");
                            }}
                          >
                            {item.data().Method == "Delivery" ? (
                              <div>
                                <p>{item.data().Address}</p>
                              </div>
                            ) : (
                              <div style={{ display: "flex" }}>
                                <p style={{ color: "#ff0000" }}>
                                  {item.data().Method}
                                </p>
                                <p>, {item.data().Name}</p>
                              </div>
                            )}
                          </div>
                          {modalState.isOpen && (
                            <Modal
                              isOpen={modalState.isOpen}
                              onClose={closeModal}
                              selectedItem={modalState.selectedItem}
                              collection={"Completedorder"}
                            />
                          )}
                        </div>
                      );
                    }
                  }
                })
              )}
            </div>
          ) : (
            <div style={{ display: "flex", flexDirection: "column" }}>
              {emailA.map((item) => {
                return (
                  <div
                    style={{
                      boxShadow: "2px 2px 4px #f0f0f0",
                      borderRadius: 5,
                      margin: 10,
                      fontFamily: "MyFont",
                      fontSize: width < "468" ? "6vw" : "2vw",
                    }}
                  >
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <p>{item}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Kokaside;
