import logo from "./img/logonobgf.png";
import background from "./img/pizzabg.jpg";
import background2 from "./img/bg2.jpg";
import background3 from "./img/bg3.jpg";
import lasagna from "./img/lasagna.png";
import garlic from "./img/garlicimg.png";
import pizza from "./img/pizza.png";
import putine from "./img/putin.png";
import dip from "./img/dippa.png";
import box from "./img/box.png";
import diner from "./img/diner.png";
import coke from "./img/coke.png";
import fish from "./img/fishchip.png";
import salad from "./img/salad.png";
import sides from "./img/sides.png";
import wings from "./img/wings.png";
import onion from "./img/onion.png";
import slide2 from "./img/slide2.png";
import pizzapic from "./img/pizzapic.png";
import React, { useState, useEffect } from "react";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import "./Home.css";
import { MdPhone } from "react-icons/md";
import { createSearchParams, NavLink } from "react-router-dom";
import CartIcon from "./carticon";
import { red } from "@mui/material/colors";
function Home() {
  const [width, setWidth] = useState(window.innerWidth);
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const handleButtonClick = () => {
    const targetClass = document.querySelector(".menuu");
    setIsButtonClicked(true);
    targetClass.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const properties = {
    duration: 2000,
    transitionDuration: 250,
    infinite: true,
    indicators: false,
    arrows: false,
    onChange: (oldIndex, newIndex) => {
      console.log(`slide transition from ${oldIndex} to ${newIndex}`);
    },
  };
  const menu = [
    { url: pizza, dis: "Build your pizza", nav: "toppings", price: 11.95 },
    { url: slide2, dis: "Pizza wings combos", nav: "pizzawings", price: 10 },
    { url: pizzapic, dis: "Flavour/ Specialty Pizza", nav: "pizza", price: 10 },
    { url: sides, dis: "Mega/Party deals", nav: "family", price: 10 },
    {
      url: wings,
      dis: "Best wings in town",
      nav: "wingstopping",
      price: 13.45,
    },
    { url: putine, dis: "Fries and Poutine", nav: "fried", price: 10 },
    { url: lasagna, dis: "Lasagna or Panzerotti", nav: "lasagna", price: 10 },
    { url: fish, dis: "Sea food", nav: "seafood", price: 10 },
    { url: diner, dis: "Diner special", nav: "special", price: 10 },
    {
      url: garlic,
      dis: "Garlic fingers w/cheese",
      nav: "garlicfingers",
      price: 9.95,
    },
    { url: onion, dis: "Side orders", nav: "sides", price: 10 },
    { url: salad, dis: "salad", nav: "salad", price: 10 },
    { url: box, dis: "Box deals", nav: "boxes", price: 10 },
    { url: coke, dis: "Pop", nav: "pop", price: 10 },
    { url: dip, dis: "Dips", nav: "dip", price: 10 },
  ];

  const fadeImages = [
    {
      url: background,
      caption: "First Slide",
    },
    {
      url: background2,
      caption: "Second Slide",
    },
    {
      url: background3,
      caption: "Third Slide",
    },
  ];
  return (
    <div className="slide-container">
      {/* <Fade {...properties}>
        {fadeImages.map((fadeImage, index) => (
          <div className="each-fade" key={index}>
            <div
              style={{
                position: "absolute",
                width: "100vw",
                zIndex: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            > */}
              <div
                style={{
                  alignSelf: "flex-start",
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <img
                  src={logo}
                  style={{
                    width: width > "450" ? "30vw" : "40vw",
                    alignSelf: "flex-start",
                    margin: 20,
                  }}
                />{" "}
                <CartIcon color={"white"} />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <h1
                  style={{
                    color: "white",
                    fontSize: "8vw",
                    fontFamily: "MyFont",
                    textAlign: "center",
                    justifySelf: "flex-start",
                    marginTop: "-2vw",
                    marginBottom: "2vw",
                    color: "red"
                  }}
                >
                  Online ordering temporarily down,
                </h1>
                <h1
                  style={{
                    color: "white",
                    fontSize: "8vw",
                    fontFamily: "MyFont",
                    textAlign: "center",
                    justifySelf: "flex-start",
                    marginTop: "-2vw",
                    marginBottom: "2vw",
                    color: "red"
                  }}
                >
                  We apologize for the inconvenience
                </h1></div>
                {/* <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "1vw",
                  }}
                >
                  <MdPhone
                    size="4vw"
                    className="nav-linker"
                    style={{
                      color: "white",
                      fontSize: "4vw",
                      marginTop: "1vw",
                      marginRight: "1vw",
                      fontFamily: "MyFont",
                      textAlign: "center",
                      justifySelf: "flex-start",
                    }}
                  />
                  <h1
                    style={{
                      color: "white",
                      display: "flex",
                      fontSize: "5vw",
                      fontFamily: "MyFont",
                      textAlign: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      margin: 0,
                    }}
                  >
                    <a
                      href="tel:+15193581212"
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      519-358-1212
                    </a>
                  </h1>
                </div>
                <button
                  style={{
                    width: "auto",
                    maxWidth: 200,
                    height: "auto",
                    padding: 15,
                    borderRadius: 25,
                    background: "#ff0000",
                  }}
                  onClick={handleButtonClick}
                >
                  <span
                    style={{
                      color: "white",
                      fontFamily: "MyFont",
                      fontSize: width < "450" ? 20 : 30,
                    }}
                  >
                    Order Online
                  </span>
                </button>
              </div>
            </div>
            <div
              className="image-container"
              style={{ overflow: "hidden", maxHeight: "100vh" }}
            >
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  background: "black",
                  position: "absolute",
                  zIndex: 1,
                  opacity: 0.5,
                }}
              ></div>
              <img
                src={fadeImage.url}
                style={{
                  width: "100vw",
                  filter: "blur(3px)",
                }}
              />
            </div>
          </div>
        ))}
      </Fade>

      <div className="menuu">
        <h1
          style={{
            color: "#ff0000",
            fontSize: width < "468" ? "8vw" : "5vw",
            fontFamily: "MyFont",
            textAlign: "center",
          }}
        >
          Please Choose
        </h1>
        <div
          style={{
            justifyContent: "space-evenly",
            display: "flex",
            width: "100vw",
            flexWrap: "wrap",
          }}
        >
          {menu.map((pics) => {
            return (
              <NavLink
                to={{
                  pathname: pics.nav,
                  search: createSearchParams({
                    Name: pics.dis,
                    price: pics.price,
                  }).toString(),
                }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  textDecoration: "none",
                }}
              >
                <img
                  src={pics.url}
                  style={{
                    width: "20vw",
                    minWidth: 150,
                    margin: 10,
                    marginTop: "auto",
                  }}
                />
                <h1
                  style={{
                    color: "#ff0000",
                    fontSize: width < "468" ? "6vw" : "3vw",
                    fontFamily: "MyFont",
                    textAlign: "center",
                    marginTop: "auto",
                  }}
                >
                  {pics.dis}
                </h1>
              </NavLink>
            );
          })}
        </div>
      </div> */}
    </div>
  );
}

export default Home;
